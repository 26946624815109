// import React, { useRef } from 'react';
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import BrandSection from "./brandSection";
import WhyBrand from "./whyBrandPromotion";
import Cards from "./brandService";

import BrandPromotionBanner from "./brandPromotionBanner";

const BrandPromotion = () => {
  window.scrollTo(0, 0);
  // const targetRef = useRef(null);

  // const scrollToComponent = () => {
  //     if (targetRef.current) {
  //         targetRef.current.scrollIntoView({ behavior: 'smooth' });
  //     }
  // };

  return (
    <div>
      <Navbar />
      <BrandPromotionBanner />
      <WhyBrand />
      <BrandSection />
      <Cards />
      <Footer />
    </div>
  );
};

export default BrandPromotion;
