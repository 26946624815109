import React from 'react'
const incentives = [
    {

        description: "Creative Brilliance: Transforming Visions into Experiences Our team of event maestros brings creativity and innovation to every detail , ensuring your special event is a one-of-a-kind experience.",

    },
    {

        description: "Strategic Planning: Precision in Every Detail From conceptualization to execution, we meticulously plan every aspect of your event, ensuring ",

    },
    {

        description:
            "Versatile Expertise: Handling Events of Every Scale and Nature Whether it's an intimate gathering or a grand celebration,our team has the versatility to curate events   that align with your unique goals.",

    },
]


const ChooseUs = () => {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-4 py-24 sm:px-6 lg:px-8">
                <div className="rounded-2xl bg-customColor px-6 py-16 sm:p-16">
                    <div className="mx-auto max-w-xl lg:max-w-none">
                        <div className="text-center">
                            <h2 className="text-2xl font-bold  text-white">
                                Why Choose Tooki Media for Special Events Management?
                            </h2>
                        </div>
                        <div className="mx-auto mt-12 grid max-w-sm grid-cols-1 gap-x-8 gap-y-10 sm:max-w-none lg:grid-cols-3">
                            {incentives.map((incentive) => (
                                <div key={incentive.name} className="text-center sm:flex sm:text-left lg:block lg:text-center">
                                    <div className="sm:flex-shrink-0">
                                        {/* <div className="flow-root">
                                            <img className="mx-auto h-16 w-16" src={incentive.imageSrc} alt="" />
                                        </div> */}
                                    </div>
                                    <div className="mt-3 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                                        <h3 className="text-sm font-medium text-white">{incentive.name}</h3>
                                        <p className="mt-2 text-md text-white text-left">{incentive.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChooseUs;