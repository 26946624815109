import React from "react";
import { AiTwotoneSetting } from "react-icons/ai";

import { FaClipboardList } from "react-icons/fa6";
import { GiChessQueen } from "react-icons/gi";
// import { FcProcess } from "react-icons/fc";
import { RiDeviceRecoverFill } from "react-icons/ri";





const CustomCard = () => {
  return (
    <div className="rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-transform duration-300 transform hover:scale-105 bg-white p-6 lg:mx-24 md:mx-18 sm:mx-12 text-center">
      <h2 className="text-3xl font-bold mb-4">Our Approach to Crisis Management</h2>

      <div className="flex flex-col items-center text-center mb-4">
        <div className="flex items-center mb-2">
          <FaClipboardList size={30} className="text-customColor_red mr-2" />
          <h3 className="text-lg font-bold">Assessment</h3>
        </div>
        <p className="text-sm text-gray-600">
        Understanding the Scope and Impact
        </p>
      </div>

      <div className="flex flex-col items-center mb-4">
        <div className="flex items-center mb-2">
          <GiChessQueen size={30} className="text-customColor_red mr-2" />
          <h3 className="text-lg font-bold">Strategy Development</h3>
        </div>
        <p className="text-sm text-gray-600">
        Crafting a Tailored Response Plan
        </p>
      </div>

      <div className="flex flex-col items-center mb-4">
        <div className="flex items-center mb-2">
          <AiTwotoneSetting  size={30} className="text-customColor_red mr-2" />
          <h3 className="text-lg font-bold">Execution</h3>
        </div>
        <p className="text-sm text-gray-600">
        Swift and Targeted Action
        </p>
      </div>

      <div className="flex flex-col items-center mb-4">
        <div className="flex items-center mb-2">
          <RiDeviceRecoverFill size={30} className="text-customColor_red mr-2" />
          <h3 className="text-lg font-bold">Recovery</h3>
        </div>
        <p className="text-sm text-gray-600">
        Rebuilding and Moving Forward
        </p>
      </div>
    </div>
  );
};

export default CustomCard;
