import React from "react";
import TextCarousel from "../Components/carousel";

const Testimonial = () => {

    const slides = [
        {
          title: "Zara Ibrahim",
          backgroundImage: "https://example.com/image1.jpg",
          description: "Tooki Media is more than an agency; they are curators of brand stories. Their media relations expertise and crisis management skills played a pivotal role in maintaining our brand integrity during challenging times. With Tooki, we didn't just weather the storm; we emerged stronger.",
        },
        {
          title: "Emeka Okoye",
          backgroundImage: "https://example.com/image2.jpg",
          description: "Tooki Media transformed our local brewery into a brand sensation. Their unique approach to digital marketing skyrocketed our online presence, and their events added a refreshing touch to our brand. Working with them was not just a collaboration; it was a journey of success.",
        },
        {
          title: "Amina Abdullahi",
          backgroundImage: "https://example.com/image3.jpg",
          description: "In the world of beauty, standing out is everything. Tooki Media not only understood our brand but sculpted a communication strategy that truly captured our essence. Their commitment to excellence and creativity is unmatched. A heartfelt thank you for making Zen Beauty shine. ",
        },
        {
            title: "Femi Oke",
            backgroundImage: "https://example.com/image3.jpg",
            description: "Navigating the digital landscape can be daunting, but not with Tooki Media by your side. Their digital marketing expertise brought our tech solutions to the forefront. The results were not just in numbers; they were in the genuine connections we built with our audience. Trust them to turn clicks into clients indeed.",
          },
      ]; 

      return (
        <header className="text-white mx-4 md:mx-8 lg:mx-20 rounded-lg overflow-hidden hover:shadow-md" data-aos="zoom-in"
        data-aos-duration="1000">
          <div
            style={{
              background: 'linear-gradient(45deg, #505e86, #cc2229)',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              borderRadius: '12px',
              transition: 'all 0.3s ease',
            }}
            className="p-6 md:p-12"
          >
            <h1 className="text-center font-bold text-3xl md:text-4xl lg:text-5xl mb-6">
              What People Are Saying About Tooki Media Agency
            </h1>
            <TextCarousel slides={slides} />
          </div>
        </header>
      );
    };
    

export default Testimonial;
