import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Container, Row, Col } from "react-bootstrap";

const BlogFeed = ({ rssFeedUrl }) => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      setIsLoading(true);
      setError(null); // Clear any previous errors

      try {
        const response = await axios.get(
          "https://api.rss2json.com/v1/api.json",
          {
            params: {
              rss_url: rssFeedUrl,
            },
          }
        );

        setBlogs(response.data.items);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogs();
  }, [rssFeedUrl]);

  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || "";
  };
  // Extract the image URL from the description
const extractImageUrl = (description) => {
  const match = description.match(/<img.*?src=["'](.*?)["']/);
  return match ? match[1] : null;
};

  return (
    <Container className="mt-14">
      {isLoading && <p className="text-center">Loading blogs...</p>}
      {error && <p className="text-center text-danger">Error fetching blogs: {error.message}</p>}
      {!isLoading && !error && (
        <Row>
          {blogs.map((blog) => (
            <Col key={blog.guid} md={4} className="mb-4">
              <Card style={{height: '550px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', transform: 'translateZ(0)' }}>
                <Card.Img
                  variant="top"
                  src={blog.thumbnail || extractImageUrl(blog.description) || 'https://picsum.photos/300/200'}
                  alt={blog.title}
                  style={{ objectFit: 'contain', height: '300px' }}
                />
                <Card.Body>
                  <Card.Title>{blog.title}</Card.Title>
                  <Card.Text>{stripHtmlTags(blog.description).slice(0, 150)}...</Card.Text>

                  <Card.Link
                    href={blog.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'red' }}
                  >
                    Read More
                  </Card.Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default BlogFeed;
