import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../Assets/logos/Passport-Legacy-350x100-WP-05-06.png.webp";
import Logo2 from "../../Assets/logos/Logo_1-1131x800.png";
import Logo3 from "../../Assets/logos/PinkClearessence-Logo-Black.png";
import Logo4 from "../../Assets/logos/Sims-logo-Black2-1024x292.png";
import Logo5 from "../../Assets/logos/WhatsApp_Image_2023-09-07_at_10.32.38_AM__1_-removebg-preview.png";
import Logo6 from "../../Assets/logos/cropped-20200409_114524_0000.png";
import Logo7 from "../../Assets/logos/cropped-cropped-logo-jpeg.jpg";
import Logo8 from "../../Assets/logos/samsung-logo-png-1304.png";
import Logo9 from "../../Assets/logos/logom1.png";
import Logo10 from "../../Assets/logos/logo (1).png";

function CarouselFadeExample() {
  const images = [
    {
      src: Logo,
      alt: "First slide",
    },
    {
      src: Logo2,
      alt: "Second slide",
    },
    {
      src: Logo3,
      alt: "Third slide",
    },
    {
      src: Logo4,
      alt: "Fourth slide",
    },
    {
      src: Logo5,
      alt: "Fifth slide",
    },
    {
      src: Logo6,
      alt: "Sixth slide",
    },
    {
      src: Logo7,
      alt: "Seventh slide",
    },
    {
      src: Logo8,
      alt: "Eighth slide",
    },
    {
      src: Logo9,
      alt: "Ninth slide",
    },
    {
      src: Logo10,
      alt: "Tenth slide",
    },
  ];

  return (
    <Carousel fade className="my-5 fixed-height-carousel" indicators={false} interval={3000} style={{ height: "300px" }} data-aos="zoom-in" data-aos-duration="1000">
      <h2 className="font-bold text-center text-2xl my-7 text-customColor">
        Our Clients
      </h2>
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          <img
            src={image.src}
            alt={image.alt}
            className="img-fluid fixed-size-image mx-auto d-block"
            style={{
              maxWidth: "300px",
              maxHeight: "200px",
              objectFit: "cover",
              borderRadius: "12px",
            }}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default CarouselFadeExample;
