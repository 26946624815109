import React from "react";
import { BsPatchCheck } from "react-icons/bs";


const items = [
  {
    title: "Basic",
    description: "N1.3 million/month",
    listItems: [
      {
        text: "Social Assets",
        icon: <BsPatchCheck />
        ,
      },
      {
        text: "Online Profiles",
        icon: "fa-solid fa-star",
      },
      {
        text: "1 Top-Tier Asset Per Month"
      },
      {
        text: "Low Aggressive Backlinks"
      }
    ],
  },
  {
    title: "Advanced",
    description: "N3.2 million/month",
    listItems: [
      {
        text: "Social Assets",
        icon: "fa-solid fa-check",
      },
      {
        text: "Online Profiles",
        icon: "fa-solid fa-star",
      },
    {
        text: "2 Top-Tier Assets Per Month"
    },
    {
        text: "Medium Aggressive Backlinks"
    },
    {
        text:"Google Training"
    },
    {
        text: "Removal and De-indexing "
    }
    ],
  },
  {
    title: "Advanced",
    description: "N5.3 million/month",
    listItems: [
      {
        text: "Social Assets",
        icon: "fa-solid fa-lightbulb",
      },
      {
        text: "Online Profiles",
        icon: "fa-solid fa-rocket",
      },
      {
        text: "3 Top-Tier Assets Per Month"
      },
      {
        text: "Aggressive Backlinks"
      },
      {
        text: "Rapid Google Training"
      },
      {
        text: "Removal and De-indexing"
      },
      {
        text: "Legal Aid"
      }
    ],
  },
 
];

// ... (previous imports)

function CardItem({ icon, text }) {
    return (
      <div className="flex items-center mb-2">
        <i className={`fas ${icon} mr-2 text-xl text-indigo-600`}></i>
        <span className="text-sm">{text}</span>
      </div>
    );
  }
  
  function CardGroup({ title, description, listItems }) {
    return (
      <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 py-4 mx-10">
        <div className="border border-gray-300 rounded-md shadow-md p-4 mb-4">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">{title}</h3>
          <p className="text-sm text-customColor_red mb-4">{description}</p>
          {listItems.map((item) => (
            <CardItem key={item.text} {...item} />
          ))}
        </div>
      </div>
    );
  }
  
  function CardsWithList() {
    return (
        <>
       
        <div className="container mx-auto px-5 sm:px-8 md:px-10 lg:px-16 xl:px-20">
        <h3 className="text-center mt-6">You're Now Viewing Our Monthly ORM Plans</h3>
            <div className="flex flex-wrap -mx-4">
                {items.map((item) => (
                    <CardGroup key={item.title} {...item} />
                ))}
            </div>
        </div>
        </>
    );
  }
  
  export default CardsWithList;
  