import React from "react";
// import { IoShieldCheckmark } from "react-icons/io5";
import { GrActions } from "react-icons/gr";
import { FaStarOfLife } from "react-icons/fa";
import { HiSpeakerphone } from "react-icons/hi";
import { MdOutlineCrisisAlert } from "react-icons/md";





const TwoColumnLayout = () => {
  const cardContent = [
    {
      title: "Strategic Resilience: Crafted Solutions for Unpredictable Scenarios",
      text: "Our Crisis Management service is a proactive strategy to safeguard your brand's reputation and integrity",
    },
    {
      title: "24/7 Crisis Response Team: Your Ally in Any Crisis",
      text: "Emergencies don't keep office hours, and neither do we. Our dedicated team is ready around the clock to address and mitigate crises promptly.",
    },
    {
      title: "Global Perspective, Local Expertise: Navigating Challenges on Every Front      ",
      text: "From local controversies to global crises, our team understands the intricacies of managing challenges on various scales.",
    },
  ];

  const servicesContent = [
    {
      icon: (
        <GrActions size={32} className="text-customColor_red mr-2" />
      ),
      heading: "Rapid Response Protocol",
      text: "Immediate Action, Thoughtful Solutions Strategic Communication Planning: Crafting the Right Message at the Right Time",
    },
    {
      icon: (
        <FaStarOfLife size={32} className="text-customColor_red mr-2" />
      ),
      heading: "Reputation Restoration",
      text: " Rebuilding Trust and Credibility",
    },
    {
      icon: (
        <HiSpeakerphone size={32} className="text-customColor_red mr-2" />
      ),
      heading: "Media Relations and Damage Control",
      text: "Managing External Perceptions",
    },
    {
      icon: (
        <MdOutlineCrisisAlert size={32} className="text-customColor_red mr-2" />
      ),
      heading: "Post-Crisis Analysis",
      text: "Learning for Future Resilience",
    },
  ];

  return (
    <>
      <div className="flex flex-col lg:flex-row p-4 md:p-8 lg:p-10  my-12">
        {/* Left Column */}
        <div className="flex-1 mb-8 lg:mb-0">
          <div className="text-center mb-6 ">
            <h2 className="text-2xl md:text-3xl lg:text-3xl font-bold">
              Why Choose Tooki Media for Crisis Management?
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
  {cardContent.map((card, index) => (
    <div
      key={index}
      className={`rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-transform duration-300 transform hover:scale-105 ${
        index === 2 ? "lg:col-span-2 lg:mx-8 " : "" // Set the third card to be twice as wide and add margin on large screens
      }`}
    >
      <div className="p-4">
        <h3 className="text-xl md:text-xl lg:text-xl font-bold mb-3">
          {card.title}
        </h3>
        <p className="text-sm md:text-base lg:text-base">{card.text}</p>
      </div>
    </div>
  ))}
</div>

        </div>

        {/* Separator Line */}
        <div className="w-full lg:w-px bg-customColor_red my-4 lg:mx-8" />

        {/* Right Column */}
        <div className="flex-1">
          <div className="text-center mb-6 ">
            <h2 className="text-2xl md:text-3xl lg:text-3xl font-bold">
              Key Components of Our Crisis Management Service
            </h2>
          </div>
          <div className="flex flex-col  m-0 p-0">
            {servicesContent.map((service, index) => (
              <div key={index} className="text-left mb-4">
                <div className="flex  justify-start mb-2">
                  {service.icon}
                  <h3 className="text-base md:text-lg lg:text-lg font-bold">
                    {service.heading}
                  </h3>
                </div>
                <p className="text-sm md:text-base lg:text-base">
                  {service.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoColumnLayout;
