import React from "react";
import Carousel from "react-bootstrap/Carousel";
import img1 from "../Assets/ourwork/0135FA14-854F-4AB5-81EE-B145F7D41578.jpeg";
import img2 from "../Assets/ourwork/038414F1-E3DE-4F63-9B8C-FEBEBA5825C0_4_5005_c.jpeg";
import img3 from "../Assets/ourwork/3BEF4B60-F95B-436C-9D5E-CC4203D933BE.jpeg";
import img4 from "../Assets/ourwork/9C6AC907-3EB7-47EC-87F6-D13FC6B1647A.jpeg";
import img5 from "../Assets/ourwork/BD49FEFF-1B5E-4A37-A9F5-D92FCFE11862.jpeg";
import img6 from "../Assets/ourwork/D52F0170-3710-4A22-932B-C72126B26C7B.jpeg";

const images = [
  { src: img1, alt: "Image 1" },
  { src: img2, alt: "Image 2" },
  { src: img3, alt: "Image 3" },
  { src: img4, alt: "Image 4" },
  { src: img5, alt: "Image 5" },
  { src: img6, alt: "Image 6" },
];

function MyCarousel() {
  return (
    <Carousel fade indicators={false} interval={4000} className="mt-8 mb-4">
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          <img
            src={image.src}
            alt={image.alt}
            className="img-fluid rounded-md shadow-md"
            style={{
              maxWidth: "100%",
              maxHeight: "400px",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default MyCarousel;
