import Img from "../../Assets/idea-3083106_1920.jpg";
import { Link } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

export default function CrisisCTA() {
  return (
    <div className="bg-white py-16 sm:py-24">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
          <img
            className="absolute inset-0 h-full w-full object-cover brightness-150 saturate-0"
            src={Img}
            alt=""
          />
          <div className="absolute inset-0 bg-gray-900/90 mix-blend-multiply" />
          <div
            className="absolute -left-80 -top-56 transform-gpu blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-[0.45]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className="hidden md:absolute md:bottom-16 md:left-[50rem] md:block md:transform-gpu md:blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#505e86] to-[#cc2229] opacity-25"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="relative mx-auto max-w-2xl lg:mx-0">
            {/* <img className="h-12 w-auto" src="https://tai#cc2229lwindui.com/img/logos/workcation-logo-white.svg" alt="" /> */}
            <h2 className="text-3xl font-bold mb-4 text-white">
              Ready to Safeguard Your Brand's Future? Let's Talk!
            </h2>
            <figure>
              <div className="mt-6 text-lg font-semibold text-white sm:text-xl sm:leading-8">
                <p>
                  Don't wait until crisis strikes; be prepared with Tooki
                  Media's Crisis Management service. Schedule a consultation
                  today and let us fortify your brand against the unexpected.
                </p>
              </div>
              <figcaption className="mt-6 text-base text-white">
                <Link to="https://calendly.com/tookimediapr">
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-2 rounded-md bg-customColor_red px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-customColor focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-customColor mb-9 "
                  >
                    Book now
                    <CheckCircleIcon
                      className="-mr-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </Link>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
}
