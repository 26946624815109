import React, { useState } from "react";
import backgroundImage from "../../Assets/austin-distel-wD1LRb9OeEo-unsplash.jpg";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

// import React, { useState } from "react";

function ContactForm({ onSubmit }) {
  window.scrollTo(0, 0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [budget, setBudget] = useState("");
  const [timeline, setTimeline] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({
      name,
      email,
      phone,
      company,
      website,
      budget,
      timeline,
      message,
    });
    // Reset state variables if needed
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="mx-auto max-w-md p-8 bg-white rounded-md shadow-md sm:max-w-lg md:max-w-xl lg:max-w-2xl xl:max-w-3xl"
  style={{ maxHeight: "80vh", overflowY: "auto",minWidth: "60vw" }}
    >

      <h2 className="text-2xl font-semibold mb-4 text-center">Backlinks Service Inquiry Form</h2>
      <p>Thank you for your interest in our Backlinks Building Service at Tooki Media Agency. Please provide us with the following information, and a member of our team will get in touch with you shortly.</p>

      <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
        Name:
        <span className="text-sm text-gray-500">(Please enter your full name)</span>
      </label>
      <input
        type="text"
        id="name"
        value={name}
        onChange={(event) => setName(event.target.value)}
        placeholder="Your Name"
        required
        className="w-full border rounded-md px-3 py-2 mb-4 focus:border-indigo-500 focus:outline-none"
      />

      <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
        Email:
        <span className="text-sm text-gray-500">(We will use this email to contact you)</span>
      </label>
      <input
        type="email"
        id="email"
        placeholder="Your Email Address"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        required
        className="w-full border rounded-md px-3 py-2 mb-4 focus:border-indigo-500 focus:outline-none"
      />

      <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
        Phone:
      </label>
      <input
        type="tel"
        id="phone"
        placeholder="Your Phone Number"
        value={phone}
        onChange={(event) => setPhone(event.target.value)}
        className="w-full border rounded-md px-3 py-2 mb-4 focus:border-indigo-500 focus:outline-none"
      />

      <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1">
        Company:
      </label>
      <input
        type="text"
        id="company"
        placeholder="Your Company Name"
        value={company}
        onChange={(event) => setCompany(event.target.value)}
        className="w-full border rounded-md px-3 py-2 mb-4 focus:border-indigo-500 focus:outline-none"
      />

      <label htmlFor="website" className="block text-sm font-medium text-gray-700 mb-1">
        Website:
      </label>
      <input
        type="url"
        id="website"
        placeholder="Your Website URL"
        value={website}
        onChange={(event) => setWebsite(event.target.value)}
        className="w-full border rounded-md px-3 py-2 mb-4 focus:border-indigo-500 focus:outline-none"
      />

      <label htmlFor="budget" className="block text-sm font-medium text-gray-700 mb-1">
        Your Budget:
      </label>
      <input
        type="number"
        id="budget"
        placeholder="Your Budget (₦)"
        value={budget}
        onChange={(event) => setBudget(event.target.value)}
        className="w-full border rounded-md px-3 py-2 mb-4 focus:border-indigo-500 focus:outline-none"
      />

      <label htmlFor="timeline" className="block text-sm font-medium text-gray-700 mb-1">
        Your Timeline:
      </label>
      <input
        type="text"
        id="timeline"
        placeholder="Desired Completion Date"
        value={timeline}
        onChange={(event) => setTimeline(event.target.value)}
        className="w-full border rounded-md px-3 py-2 mb-4 focus:border-indigo-500 focus:outline-none"
      />

      <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
        Message:
      </label>
      <textarea
        id="message"
        value={message}
        onChange={(event) => setMessage(event.target.value)}
        required
        className="w-full border rounded-md px-3 py-2 mb-4 resize-none focus:border-indigo-500 focus:outline-none"
      />

      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          id="terms"
          required
          className="mr-2 border rounded-md focus:border-indigo-500"
        />
        <label htmlFor="terms" className="text-sm text-gray-700">
          I agree to the
          <a href="#h" className="text-indigo-600 hover:underline">
            Privacy Policy
          </a>
          and
          <a href="#h" className="text-indigo-600 hover:underline">
            Terms of Service
          </a>
        </label>
      </div>

      <button
        type="submit"
        className="w-full bg-indigo-600 text-white font-semibold py-2 rounded-md hover:bg-indigo-700 focus:outline-none"
      >
        Submit
      </button>
    </form>
  );
}


// Full background contact component
function FullBackgroundContact({ imageUrl = backgroundImage }) {
  return (
    
    <>
      <Navbar />
      <div
        className="h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <div className="flex justify-center items-center h-full ">
          <ContactForm onSubmit={(data) => console.log(data)} />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FullBackgroundContact;
