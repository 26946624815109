import React from "react";
import "./Banner.css";
import { Link } from "react-router-dom";

// import Star from "../../Assets/five star.jpeg";
// import svg from "../../Assets/undraw_articles_wbpb.svg"
const Banner = () => {
  return (
    <div className="tooki__banner background__banner">
      <div className="tooki__banner-container">
        <div className="tooki__banner-container_item1">
          <div className="tooki_banner-container_item1-detail">
            <h1 data-aos="fade-up" data-aos-duration="1000">We Are a Global Public Relations and Digital Marketing Agency for Corporate Brands and Nonprofits.</h1>
            <p data-aos="fade-up" data-aos-duration="1000">
            Welcome to a world where innovation meets impact. At Tooki Media, we're not just about marketing; we're about crafting experiences that resonate. From the bustling streets of Lagos, Nigeria,  to the digital landscape, we infuse every campaign with the unique spirit of your brand.

            </p>
            <div className="tooki_banner-container_item1-detail-div mt-6 hover:bg-customColor" data-aos="fade-up" data-aos-duration="1000">
              <Link to='/contactus'><button className="btn1 hover:bg-customColor">Contact Us</button></Link>
              {/* <button className="btn2">How it works</button> */}
              {/* <div className="rating">
                <p>Rated Excellent</p>
                <img src={Star} alt="star"></img>
                <p>4.6 on trustpilot</p>
              </div> */}
            </div>
          </div>
          {/* <div className="tooki_banner-container_item1-video">
            <img src={svg} alt=""  />
          </div> */}
        </div>
        {/* <div className="tooki__banner-container_two">
          <div className="tooki__banner-container_two-content">
            <h1>24%</h1>
            <h1>/</h1>
            <div className="tooki__banner-container_two-content-div">
              <p>
                "Our blog is now getting a 24% Conversion Rate after working
                with Brand Featured. Amazing.”
              </p>
              <h5>TOOLS OFFICIAL</h5>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Banner;
