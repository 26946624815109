import React from "react";
import {
  GiSwordBrandish,
  GiRelationshipBounds,
} from "react-icons/gi";
import { TbSeo, TbTrafficLights } from "react-icons/tb";
import { SiTrustpilot, SiWikipedia } from "react-icons/si";
import { TbStarsFilled } from "react-icons/tb";
import { Link } from "react-router-dom";


const BoostSales = () => {
  const cards = [
    {
      icon: GiSwordBrandish,
      title: "Brand Promotions",
      description:
        "Let's make your brand the talk of the town, from local markets to the online sphere.        ",
      link: "/BrandPromotion",
    },
    {
      icon: TbSeo,
      title: "Digital Marketing",
      description:
        "Navigate the digital jungle with precision. We blend creativity with analytics to ensure your brand stands out.",
      link: "/digital-marketing",
    },
    {
      icon: TbTrafficLights,
      title: "Special Events",
      description:
        "Turn moments into memories. Our events are not just gatherings; they're experiences that leave an indelible mark.",
      link: "/special-event",
    },
    {
      icon: TbStarsFilled,
      title: "Reputation management",
      description:
        "Our services include helping you improve your online reviews, removing negative articles, creating positive backlinks, and more. We are here to get you back on track.        ",
      link: "/media-management",
    },
    {
      icon: SiTrustpilot,
      title: "Crisis Management",
      description:
        "When the storms hit, we're your anchor. Navigate challenges with resilience and grace. ",
      link: "/crisisManagement",
    },
    {
      icon: GiRelationshipBounds,
      title: "Media Relations",
      description:
        "Building bridges between your brand and the media landscape, we ensure your story is told authentically",
      link: "/MediaRelation",
    },
    {
      icon: SiWikipedia,
      title: "Wikipedia Biography Publishing",
      description:
        "Your brand legacy deserves a place in the digital archives. Let us etch your story on the pages of Wikipedia.",
      link: "#j",
    },
  ];

  return (
    <div className="text-center mx-12">
      <div className="p-4" data-aos="zoom-in" data-aos-duration="1000">
        <h3 className="text-3xl font-bold mb-4">Explore Our Services</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {cards.map((card, index) => (
            <Link
              to={card.link}
              key={index}
              style={{ textDecoration: "none" }}
              data-aos="zoom-in"
              data-aos-delay={`${index * 100}`} // Adjust the delay duration
            >
              <div
                className="bg-white p-4 rounded-md shadow-md transform transition-transform duration-200 hover:-translate-y-1 hover:shadow-lg hover:bg-gray-100 hover:rotate-3"
                style={{
                  boxShadow: `0px 0px ${index * 50}px rgba(0, 0, 0, 0.2)`,
                  background: `linear-gradient(145deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.25) 100%)`,
                }}
              >
                <card.icon size={32} className="text-customColor_red mb-2 mx-auto" />
                <h2 className="text-xl text-customColor font-semibold">{card.title}</h2>
                <p className="text-gray-600">{card.description}</p>
              </div>
            </Link>
          ))}
        </div>
        <a
          href="https://calendly.com/tookimediapr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="bg-customColor_red text-white py-2 px-4 mt-4 rounded-full hover:bg-customColor">
            Book a call
          </button>
        </a>
      </div>
    </div>
  );
};

export default BoostSales;
