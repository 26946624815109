

import React from 'react';
import BlogFeed from './Blog';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import BlogBanner from './Banner';

function BlogApp() {
  const rssFeedUrl = 'https://medium.com/feed/@@TookiMediaPR';

  return (
    <>
    <Navbar />
    <BlogBanner/>
      {/* <h1 className="mt-3 mb-4 text-center lead">Latest Blog Posts</h1> */}
      <BlogFeed rssFeedUrl={rssFeedUrl} />
    <Footer />
    </>
  );
}

export default BlogApp;
