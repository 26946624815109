import React from 'react';
import Banner from '../../Assets/get-your-brand-digitally-promoted-6081619_1920.jpg';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

const BrandPromotionBanner = ({ scrollToComponent }) => {
    return (
        <div className="relative h-screen bg-gray-200 overflow-hidden">
            {/* Image */}
            <img
                className="object-cover object-center h-full w-full mx-auto overflow-hidden"
                src={Banner} 
                alt="Banner info"
            />

            {/* Overlay */}
            <div className=" absolute inset-0 bg-blue-900 bg-opacity-75"></div>


            {/* Text on top of the image */}
            <div className=" absolute inset-0 flex items-center justify-center text-center text-white my-10 mx-5 ">
                <div className="flex flex-col items-center justify-center text-center">
                    <h1 className="text-4xl mt-3 lg:text-6xl font-bold mb-4">
                    Brand Promotions 
                    </h1>
                    <p className="text-lg lg:w-[600px] leading-2 lg:leading-20 text-left ">
                    We create dynamic campaigns that engage your audience across multiple marketing channels. From social media to traditional marketing channels, we ensure your brand is everywhere your audience is. Our approach helps increase brand visibility and leave a lasting impression. Book a free consultation today to explore various brand promotion tactics for your business. 



                    </p>
                    <div className="mt-2">
                        <Link to='https://calendly.com/tookimediapr'>
                        <button
                            onClick={scrollToComponent}
                            type="button"
                            className="inline-flex items-center gap-x-2 rounded-md bg-customColor_red px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-customColor focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-customColor mb-9 "
                        >
                            Book now
                            <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                        </button>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default BrandPromotionBanner;
