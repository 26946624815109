import React from 'react';
import './contact.css';
import Calendly from '../../Components/calendly';

import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import ContactForm from '../../Components/Contact/ContactForm/ContactForm';
import Connect from "../../Components/Contact/Connets";
import ContactBanner from '../../Components/Contact/ContactBanner';


const ContactUs = () => {
    window.scrollTo(0, 0);
    return (
        <div>
            <Navbar />
            <div className='h-[100px]'>
                <ContactBanner />
            </div>
            <ContactForm />
            <Connect />
            <Calendly />

            <Footer />
        </div>
    )
}

export default ContactUs;