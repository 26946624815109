import React from "react";
import MyCarousel from "../whycarousel";

const WhyTookimedia = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="container mx-auto flex flex-col md:flex-row items-center md:items-start justify-between">
        <div className="md:w-1/2 mx-6 mb-12 " data-aos="zoom-in" data-aos-duration="1000">
          <h2 className="font-bold text-center md:text-left text-3xl mb-4">
            Explore Our Work
          </h2>
          <MyCarousel />
        </div>
        <div className="md:w-1/2 lg:mt-10 " data-aos="fade-up" data-aos-duration="1000">
          <h2 className="font-bold text-center md:text-left text-3xl mb-4">
            Why Choose Tooki Media
          </h2>
          <div className="mb-6">
            <h4 className="font-bold text-customColor_red text-base mb-2">
              Global Marketing Communications Expertise
            </h4>
            <p>
              We bring a unique blend of global marketing communications
              expertise and local authenticity, setting us apart from the
              competition.
            </p>
          </div>
          <div className="mb-6">
            <h4 className="font-bold text-customColor_red text-base mb-2">
              Conversion Magic
            </h4>
            <p>
              Our strategies go beyond visibility; they're designed to turn
              curious clicks into devoted clients.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyTookimedia;
