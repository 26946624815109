import React from "react";
import { HiMiniCheckBadge } from "react-icons/hi2";
import { RiPresentationLine } from "react-icons/ri";
import { FcCollaboration, FcEngineering} from "react-icons/fc";
import { BiReflectVertical } from "react-icons/bi";





const SpecialApproach = () => {
    return (
        <section className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
                        {/* <img className="h-12 self-start" src="https://tailwindui.com/img/logos/tuple-logo-gray-900.svg" alt="" /> */}
                        <figure className="mt-10 flex flex-auto flex-col justify-between">
                            <blockquote className="text-lg leading-8 text-gray-900">
                                <h2>Our Approach to Special Events Management</h2>
                                {/* <p>
                                    Consultation: Understanding Your Vision and Goals
                                    Collaboration: Working Hand-in-Hand to Bring Your Vision to Life
                                    Execution: Meticulously Managing Every Aspect of the Event
                                    Reflection: Post-Event Analysis for Continuous Improvement

                                </p> */}
                                <p className="flex items-center gap-2">
                                    <RiPresentationLine className="text-customColor_red" size={50}/>
                                    Consultation: Understanding Your Vision and Goals

                                </p>
                                <p className="flex items-center gap-2">
                                    < FcCollaboration className="text-customColor_red" size={50}/>
                                    Collaboration: Working Hand-in-Hand to Bring Your Vision to Life
                                </p>
                                <p className="flex items-center gap-2">
                                    <FcEngineering className="text-customColor_red" size={50}/>
                                    Execution: Meticulously Managing Every Aspect of the Event
                                </p>
                                <p className="flex items-center gap-2">
                                    <BiReflectVertical className="text-customColor_red" size={50}/>
                                    Reflection: Post-Event Analysis for Continuous Improvement
                                </p>
                                {/* <p className="flex items-center gap-2">
                                    <HiMiniCheckBadge className="text-customColor_red" size={40}/>
                                    Post-Event Analysis: Learning for Future Success
                                </p> */}
                            </blockquote>
                        </figure>
                    </div>
                    <div className="flex flex-col border-t border-customColor pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
                        {/* <img className="h-12 self-start" src="https://tailwindui.com/img/logos/reform-logo-gray-900.svg" alt="" /> */}
                        <figure className="mt-10 flex flex-auto flex-col justify-between">
                            <blockquote className="text-lg leading-8 text-gray-900">
                                <h2>Key Features of Our Special Events Management Service</h2>

                                <p className="flex items-center gap-2">
                                    <HiMiniCheckBadge className="text-customColor_red" size={40}/>
                                    Conceptualization and Theme Development: Crafting the Perfect Ambiance
                                </p>
                                <p className="flex items-center gap-2">
                                    <HiMiniCheckBadge className="text-customColor_red" size={40}/>
                                    Logistics and Vendor Management: Seamless Execution Behind the Scenes
                                </p>
                                <p className="flex items-center gap-2">
                                    <HiMiniCheckBadge className="text-customColor_red" size={40}/>
                                    Guest Experience Enhancement: Ensuring Every Attendee Feels Special
                                </p>
                                <p className="flex items-center gap-2">
                                    <HiMiniCheckBadge className="text-customColor_red" size={40}/>
                                    Branding Integration: Weaving Your Brand into the Event Fabric
                                </p>
                                <p className="flex items-center gap-2">
                                    <HiMiniCheckBadge className="text-customColor_red" size={40}/>
                                    Post-Event Analysis: Learning for Future Success
                                </p>

                            </blockquote>

                        </figure>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SpecialApproach;