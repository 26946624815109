import React from "react";
import Banner from "../../../Assets/headway.jpg";
// import { CheckCircleIcon } from "@heroicons/react/20/solid";

const SpecialBanner = ({ scrollToComponent }) => {
    return (
        <div className="relative bg-gray-200  overflow-hidden">
            {/* Image */}
            <img
                className="object-cover object-center h-screen w-screen "
                src={Banner} // Replace this with your image URL
                alt="Banner info"
            />

            {/* Overlay */}
            <div className="absolute inset-0 bg-black  bg-opacity-75"></div>

            {/* Text on top of the image */}
            <div className="container absolute inset-0 flex items-center justify-center  text-white md:mx-6 sm:mx-6">
                <div>
                    <h1 className="text-4xl lg:text-6xl text-center font-bold mb-4">
                        Special Events
                    </h1>
                    <p className="text-lg lg:w-[600px] leading-2 lg:leading-20 ">
                        Create extra-ordinary moments with Tooki Media events services in Nigeria.
                        At Tooki Media, we specialize in transforming your vision into unforgettable experiences, whether it's a corporate gala, product launch, or personal celebration.

                    </p>
                </div>
            </div>
        </div>
    );
};

export default SpecialBanner;