import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import { PiFacebookLogoLight } from 'react-icons/pi'
import { AiOutlineInstagram } from 'react-icons/ai'
import { PiLinkedinLogo } from 'react-icons/pi'


const Footer = () => {
    // Get the current year
  const currentYear = new Date().getFullYear();
    return (
        <div className='tooki__footer'>
            <div className='tooki__footer-container'>
                <div className='tooki__footer-container-links'>
                    <ul>
                        <li><Link to='/aboutus'>About us</Link></li>
                        <li><Link to='/'>Services</Link></li>
                        <li><Link to='/Blog'>Blog</Link></li>
                        <li><Link to='/buyBackLink'>Buy Backlinks</Link></li>
                        {/* <li><Link to='/aboutus'>How it Works</Link></li> */}
                        <li><Link to='/contactus'>Contact Us</Link></li>
                        <li><Link to='/Faq'>FAQ</Link></li>
                        {/* <li><Link to='/aboutus'>Resources </Link></li> */}
                    </ul>
                </div>
                <div className='tooki__footer-container-socials'>
                    <a href='https://www.facebook.com/tookimediapr?mibextid=LQQJ4d' target='_blank' rel="noreferrer"><PiFacebookLogoLight size={30} className='social' /></a>
                    <a href='https://instagram.com/tooki_media?igshid=YTQwZjQ0NmI0OA==' target='_blank' rel="noreferrer"><AiOutlineInstagram size={30} className='social' /></a>
                    <a href='https://ng.linkedin.com/company/tooki-media' target='_blank' rel="noreferrer"><PiLinkedinLogo size={30} className='social' /></a>
                    
                    
                </div>
                <p className='tooki__footer-container-location'>
                    Happily based in Lagos Nigeria
                </p>
                <div className='tooki__footer-container-tc'>
                    <p>@{currentYear} Tooki Media</p>
                    <p>Privacy Policy</p>
                    <p>Terms and Conditions</p>
                </div>
            </div>
        </div>
    )
}

export default Footer