import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; //
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AboutUs from './Pages/AboutUs/AboutUs';

import Faq from './Components/Faq/Faq';
import BlogApp from './Components/blog/BlogList';

import ContactUs from './Pages/ContactUs/ContactUs';
import MediaRelations from './Pages/MediaRelations/MediaRelation';
import MediaManagement from './Pages/MediaManagement/MediaManagement';
import FullBackgroundContact from './Components/buybacklink/buyBackLink';
import DigitalMarking from './Pages/DigitalMarking';

import SpecialEvent from './Pages/SpecialEvent/SpecialEvent';

import BrandPromotion from './Pages/brandPromotion/BrandPromotion';
import CrisisManagement from './Pages/crisisManagement/crisisManagement';
import WikiBio from './Pages/wikipedia/wikipedia';
import ReactGA from 'react-ga';

ReactGA.initialize('G-YHTXD36QD5');

AOS.init();
const router = createBrowserRouter([
  

  {
    path: '/',
    element: <App />
  },
  {
    path: '/aboutus',
    element: <AboutUs />
  },
  {
    path: '/contactus',
    element: <ContactUs />
  },
  {
    path: '/MediaRelation',
    element: <MediaRelations />
  },
{
   path: '/BrandPromotion',
   element: <BrandPromotion />

},
{
  path: '/crisisManagement',
  element: <CrisisManagement />
},
  {
    path: '/media-management',
    element: <MediaManagement />
  },
  {
    path: '/buyBackLink',
    element: <FullBackgroundContact />
  },
  {
    path: '/digital-marketing',
    element: <DigitalMarking />
  },
  {

    path: '/special-event',
    element: <SpecialEvent />
  },
  {
     path: '/wikipedia',
     element: <WikiBio/>
  },
  {
    path: '/Faq',
    element: <Faq />
  },
  {
    path: '/Blog',
    element: <BlogApp />
  }
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
