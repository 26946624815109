// ContactBanner.jsx
import React from 'react';
import Banner from '../../../Assets/julian-hochgesang-psGV5KhidlY-unsplash.jpg'


function ContactBanner(props) {
    return (

        <div className="relative h-[700px] overflow-hidden">
            {/* Image */}
            <img
                className="object-cover object-center h-[400] w-full"
                src={Banner} // Replace this with your image URL
                alt="Banner info"
            />

            {/* Gradient Overlay */}
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50" />

            {/* Text on top of the image */}
            <div className="absolute inset-0 flex items-center justify-center text-center text-white">
                <div>
                    <h1 className="text-4xl lg:text-6xl font-bold mb-4">Contact US</h1>
                    <p className="text-lg lg:w-[600px] leading-2 lg:leading-20">
                        {/* Let's Get connected */}
                    </p>


                </div>
            </div>
        </div>
    );

    }
    export default ContactBanner;
