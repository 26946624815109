// import React, { useRef } from 'react'
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import ImageOverlayCard from './wikiPageBanner';



const WikiBio = () => {
    window.scrollTo(0, 0);
    // const targetRef = useRef(null);

    // const scrollToComponent = () => {
    //     if (targetRef.current) {
    //         targetRef.current.scrollIntoView({ behavior: 'smooth' });
    //     }
    // };

    return (
        <div>
            <Navbar />
            <ImageOverlayCard/>
            <Footer />
        </div>
    );
};

export default WikiBio;