import React, { useRef } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import DigitalBanner from '../../Components/DigitalMarking/DigitalBanner';
import WhyTookiDigital from '../../Components/DigitalMarking/WhytookiDigital';
import DigialPackages from '../../Components/DigitalMarking/DigitalPackage';

const DigitalMarking = () => {
    window.scrollTo(0, 0);
    const targetRef = useRef(null);

    const scrollToComponent = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div>
            <Navbar />
            <DigitalBanner scrollToComponent={scrollToComponent} />
            <DigialPackages targetRef={targetRef} />
            <WhyTookiDigital />

            <Footer />
        </div>
    );
};

export default DigitalMarking;