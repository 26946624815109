// import React, { useRef } from 'react'
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import WhyCrisis from './whyCrisis';
import CrisisManagementBanner from './crisisManagementBanner';
import TwoColumnLayout from './whyTookiCrisis';
import CustomCard from './approach';
import CrisisCTA from './cta';


const CrisisManagement = () => {
    window.scrollTo(0, 0);
    // const targetRef = useRef(null);

    // const scrollToComponent = () => {
    //     if (targetRef.current) {
    //         targetRef.current.scrollIntoView({ behavior: 'smooth' });
    //     }
    // };

    return (
        <div>
            <Navbar />
            <CrisisManagementBanner/>
            <TwoColumnLayout/>
           <WhyCrisis/>
           <CustomCard/>
           <CrisisCTA/>
            <Footer />
        </div>
    );
};

export default CrisisManagement;