import React from "react";

const cardData = [
  {
    image: "../../Assets/austin-distel-wD1LRb9OeEo-unsplash.jpg",
    title: "Card Title 1",
    description: "This is a sample description for the card."
  },
];

const ImageOverlayCard = () => {
  const cardHeight = "70vh"; // Card occupies 70% of the viewport height
  const imageHeight = "40vh"; // Image occupies 40% of the viewport height
  const borderWidth = "10px"; // Card overlaps the image border by 10px

  return (
    <div className="relative h-screen flex flex-col justify-center">
      {cardData.map((card) => (
        <div key={card.image}>
          {/* Background image */}
          <img
            src={card.image}
            alt={card.title}
            className="absolute inset-0 object-cover h-full w-full"
          />

          {/* Overlay */}
          <div className="absolute inset-0 bg-black opacity-50"></div>

          {/* Card */}
          <div
            className={`absolute bg-white p-8 rounded-lg shadow-md bottom-0`}
            style={{ height: cardHeight, overflow: "hidden" }}
          >
            <div
              style={{
                height: imageHeight,
                background: `url(${card.image}) no-repeat center center / cover`,
              }}
            ></div>
            <div
              style={{
                padding: "16px",
                height: `calc(${cardHeight} - ${imageHeight})`,
                transform: `translateY(-${borderWidth})`,
              }}
            >
              <h2 className="text-xl font-bold mb-4">{card.title}</h2>
              <p className="text-base text-gray-700">{card.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageOverlayCard;
