import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

const accordionData = [
  {
    heading: 'How do you remove negative links?',
    question: 'We use a combination of strategies including appeals based on empathy, financial incentive, legal recourse, and more.',
  },
  {
    heading: 'Do you have any guarantees',
    question: 'Google changes its indexing rules periodically, making it impossible for us or anyone else to make guarantees. However, we do guarantee that you will see progress and gain new media assets each month.',
  },
  {
    heading: 'Is there a minimum commitment?',
    question: 'Yes, if you choose to work with us it has to be for a minimum of three months. This is the average time frame to see significant success.    ',
  },
  {
    heading: 'Which package should I purchase?',
    question: 'Your choice will depend on search volume, quality of negative links, and our ability to remove negative material. Speak to our ORM expert for guidance.    ',
  },
  {
    heading: 'How long does it take to see results?',
    question: 'You should start to see results within two weeks. Factors that may affect results include the strength of negative links, location, elapsed time, and the aggressiveness of the campaign.',
  },
  {
    heading: 'Can we speed up the process?',
    question: 'There are some things that we can do to speed things up, but Google is smart. If we do too much too fast, it may be counterproductive.    ',
  },
  // Add more items as needed
];

function DynamicAccordion() {
  return (
    <Accordion defaultActiveKey="0" className='container'>
        <h2 className='text-center'>Online Reputation Management FAQs</h2>
      {accordionData.map((item, index) => (
        <Accordion.Item key={index} eventKey={index.toString()}>
          <Accordion.Header className='bg-customColor'>{item.heading}</Accordion.Header>
          <Accordion.Body className='bg-dark text-white'>
            <p>{item.question}</p>
            {/* You can customize the content further based on your needs */}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default DynamicAccordion;
