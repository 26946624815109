import React from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import SpecialBanner from '../../Components/SpecialEvent/SpecialBanner';
import ChooseUS from '../../Components/SpecialEvent/ChooseUs';
import SpecialApproach from '../../Components/SpecialEvent/Approach';
import TranformVision from '../../Components/SpecialEvent/Transform';


const SpecialEvent = () => {
    window.scrollTo(0, 0);
    return (
        <>
            <Navbar />
            <SpecialBanner />
            <SpecialApproach />
            <ChooseUS />
            <TranformVision />
            <Footer />
        </>
    );
};

export default SpecialEvent;
