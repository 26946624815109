import React from "react";

const Card = ({ title, text }) => {
  return (
    <div className=" bg-black h-full rounded-md overflow-hidden shadow-md ">
      <div className="p-4">
        <h3 className="text-xl text-customColor_red font-bold mb-2">{title}</h3>
        <p className="text-white">{text}</p>
      </div>
    </div>
  );
};

const Cards = () => {
  const cards = [
    {
      title: "Product Launch Campaigns",
      text:
        "Make a Splash from Day One",
    },
    {
      title: "Seasonal & Holiday Promotions",
      text:
        "Capitalize on Festive Trends",
    },
    {
      title: "Collaborative Partnerships",
      text:
        "Strategic Alliances for Mutual Success",
    },
    {
      title: "Contests & Giveaways",
      text:
        "Excite Your Audience and Drive Engagement",
    },
    {
        title: "Radio / Billboards / TV Advertisement",
        text:
          "Explore more channels for brand exposure",
      },
  ];

  return (
    <div className="container my-20">
      <h2 className="text-3xl font-bold mb-4 text-center">
        Our Brand Promotions Services Include
      </h2>
    <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
      {cards.map((card) => (
        <Card key={card.title} title={card.title} text={card.text} />
      ))}
    </div>
    </div>
  );
};

export default Cards;
