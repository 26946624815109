import React from "react";
import Banner from "./../../Assets/andrew-neel-cckf4TsHAuw-unsplash.jpg";
// import { CheckCircleIcon } from "@heroicons/react/20/solid";
// import { Link } from "react-router-dom";

const BlogBanner = ({ scrollToComponent }) => {
  return (
    <div className="relative h-[70vh] bg-gray-200 overflow-hidden ">
      {/* Image */}
      <img
        className="object-cover object-center h-full w-full mx-auto overflow-hidden"
        src={Banner}
        alt="Banner info"
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-75"></div>

      {/* Text on top of the image */}
      <div className=" absolute inset-0 flex items-center justify-center text-center text-white my-10 mx-5 ">
        <div className="flex flex-col items-center justify-center text-center">
          <h1 className="text-4xl mt-3 lg:text-6xl sm:text-2xl font-bold mb-4">Blog</h1>
          <p>Insights <span className="text-customColor_red">|</span> Stories <span className="text-customColor_red">|</span> and More</p>
          <p className="text-lg lg:w-[600px] leading-2 lg:leading-20 text-left ">
            Dive into the world of marketing, events, and brand strategy with
            our blog. Discover tips, trends, and stories that inspire and
            educate. We're not just here to provide services; we're here to
            share knowledge and empower you on your brand journey. Ready to
            embark on a transformative journey with Tooki Media? Let's make your
            brand the story everyone's talking about.
          </p>
          {/* <div className="mt-2">
            <Link to="https://calendly.com/tookimediapr">
              <button
                onClick={scrollToComponent}
                type="button"
                className="inline-flex items-center gap-x-2 rounded-md bg-customColor_red px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-customColor focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-customColor mb-9 "
              >
                Book now
                <CheckCircleIcon
                  className="-mr-0.5 h-5 w-5"
                  aria-hidden="true"
                />
              </button>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BlogBanner;
