import React from "react";

const Card = ({ title, description, icon }) => {
  return (
    <div className="relative overflow-hidden transform hover:scale-105 transition-transform duration-300">
      <div className="relative z-10 bg-white rounded-xl shadow-md overflow-hidden">
        <div className="rounded-t-xl bg-gradient-to-r from-customColor to-customColor_dark p-6">
          <div className="rounded-full bg-customColor p-3">
            {icon}
          </div>
        </div>
        <div className="p-6">
          <h2 className="text-xl font-semibold text-customColor">{title}</h2>
          <p className="my-6 mx-6 text-gray-600">{description}</p>
          <a
            href="https://calendly.com/tookimediapr"
            target="_blank"
            rel="noreferrer"
          >
            <button className="bg-customColor_red text-white px-4 py-2 rounded-md hover:bg-customColor">
              Book a call
            </button>
          </a>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full h-2 bg-customColor"></div>
    </div>
  );
};

export default Card;
