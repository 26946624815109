import React from 'react';
import Banner from "../../../Assets/austin-distel-wD1LRb9OeEo-unsplash.jpg";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const DigitalBanner = ({ scrollToComponent }) => {
    return (
        <div className="relative bg-gray-200  overflow-hidden">
            {/* Image */}
            <img
                className="object-cover object-center h-screen w-screen "
                src={Banner} // Replace this with your image URL
                alt="Banner info"
            />

            {/* Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-100"></div>

            {/* Text on top of the image */}
            <div className="container absolute inset-0 flex items-center justify-center text-center text-white">
                <div>
                    <h1 className="text-4xl lg:text-6xl font-bold mb-4">
                        Digital Marketing
                    </h1>
                    <p className="text-lg lg:w-[600px] leading-2 lg:leading-20 text-left">
                        Tooki Media helps you navigate the digital
                        landscape with finesse. Our strategies
                        are not just about visibility; they're about
                        conversions. From strategic planning to
                        tactical execution, we offer a comprehensive
                        range of digital marketing services tailored
                        to meet your specific needs. Our expertise
                        spans across various key areas including
                        Pay-Per-Click (PPC) Advertising, Social
                        Media Marketing, Content Marketing, Email Marketing,
                        Analytics and Reporting. Book a free
                        consultation today to discover how our digital marketing
                        expertise can improve your brand's bottomline.
                    </p>

                    <div className="mt-5">
                        <button
                            onClick={scrollToComponent}
                            type="button"
                            className="inline-flex items-center gap-x-2 rounded-md bg-customColor_red px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-customColor focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-customColor"
                        >
                            Call now
                            <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DigitalBanner;