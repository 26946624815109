import React from "react";
import Card from "./card";
import { PiNumberSquareOneFill } from "react-icons/pi";
import { PiNumberSquareTwoDuotone } from "react-icons/pi";
import { PiNumberSquareThreeFill } from "react-icons/pi";

const HowItWorks = () => {
  const cardsData = [
    {
      title: 'Schedule A Call',
      description: "Fill out the “Contact Us” form, and a Tooki Media representative will contact you to schedule a quick, 12-minute call.",
      icon: <PiNumberSquareOneFill size={50} />,
    },
    {
      title: "Talk To A Marketing Executive",
      description: "During the call, a Tooki Media Press Executive will review your brand and your the current objectives of either an IPO, Exit or Capital Raise.   ",
      icon: <PiNumberSquareTwoDuotone size={50} />,
    },
    {
      title: "Receive A Custom Plan",
      description: "A custom strategy will be built around increasing your enterprise value. ",
      icon: <PiNumberSquareThreeFill size={50} />,
    },
  ];

  return (
    <div className="App">
      <h2 className="font-bold text-center text-2xl my-7 sm:mx-4">
        How To Get Started With Tooki Media Agency
      </h2>

      <div className="flex flex-wrap">
        {cardsData.map((card, index) => (
          <div
            key={index}
            className="w-full md:w-1/2 lg:w-1/3 p-4"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <Card
              title={card.title}
              description={card.description}
              icon={card.icon}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
