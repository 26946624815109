import { FaStar } from "react-icons/fa";

export default function WhyBrand() {
  return (
    <div className="container my-24 text-center">
      <h2 className="text-3xl font-bold mb-8 text-customColor_red">
        Why Choose Tooki Media for Brand Promotions?
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <FeatureItem>
          <h4>Proven Expertise</h4>
          <p>Backed by a track record of successful campaigns.</p>
        </FeatureItem>

        <FeatureItem>
          <h4>Optimize Collaborative Partnership</h4>
          <p>Your goals are our goals.</p>
        </FeatureItem>

        <FeatureItem>
          <h4>Generate Global Reach, Local Impact</h4>
          <p>From local communities to global audiences, we tailor promotions for maximum impact.</p>
        </FeatureItem>
      </div>
    </div>
  );
}

const FeatureItem = ({ children }) => (
  <div className="bg-white p-6 rounded-md shadow-md">
    <p className="mb-2">
      <FaStar className="text-customColor_red inline-block mr-2" />
      {children}
    </p>
  </div>
);
