import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// ... (previous imports)

const TextCarousel = ({ slides }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      effect="fade"
      className="relative"
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index} className="relative flex items-center justify-center text-white mt-12">
          <div
            className="p-6 rounded-lg shadow-md text-center"
            style={{
              background: 'linear-gradient(45deg, #505e86, #cc2229)',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              borderRadius: '12px',
              backgroundImage: `url(${slide.backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s ease',
              transform: 'perspective(1000px) rotateY(10deg)',
            }}
          >
            <h2 className="text-3xl md:text-4xl lg:text-5xl text-customColor font-bold mb-4 mx-4 sm:mx-8 md:mx-12 lg:mx-20">
              {slide.title}
            </h2>
            <p className="text-white-600 mb-6 mx-4 sm:mx-8 md:mx-12 lg:mx-20 lg:px-20 text-left">{slide.description}</p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TextCarousel;
